<template>
  <div class="cases">
    <div>
        <img src="@/assets/yibo/2/官网轮播图@2x.png" alt="" style="width:100%;height:auto;">
    </div>
    <homeSwiper :list="bannerList"/>
    
    <div class="case">
      <div class="case_one">
          <div class="case_conTop">
            <!-- <div class="title">服务覆盖</div>
            <div class="subtitle">智慧停车 · 引领未来</div>
            <div class="line"></div> -->
          </div>
          <div class="case_content">
            <img src="@/assets/yibo/2/组 494@2x.png" alt="" style="width:100%;">
          </div>
      </div>
      <div class="case_one">
          <div class="case_conTop">
            <!-- <div class="title">服务覆盖</div>
            <div class="subtitle">智慧停车 · 引领未来</div>
            <div class="line"></div> -->
          </div>
          <div class="case_content">
            <img src="@/assets/yibo/2/组 495@2x.png" alt="" style="width:100%;"  @click="readMoreApply">
          </div>
      </div>
      <!-- <div class="case_one">
          <div class="case_conTop">
            <div class="title">工程案例</div>
            <div class="subtitle">智慧城市 · 塑造未来</div>
            <div class="line"></div>
          </div>
          <div class="case_content2">
            <img src="@/assets/tongtong/2/组 2244@2x.png" alt="" style="width:100%;">
          </div>
          <div style="color:#999999;font-size:18px;margin-top:20px;text-align:right;cursor: pointer;" @click="readMoreApply">
              查看更多
              <img src="@/assets/img/readMore.png" alt="" width="20" height="20" style="vertical-align:middle;">
          </div>
      </div> -->
      
    </div>

  </div>
</template>
<script>
import service from '@/api/service.js'
import Case from '@/api/case.js'
import banner from '@/api/banner.js'
import homeSwiper from '@/components/banner.vue'
export default {
  components: {
    // HelloWorld,
    homeSwiper
  },
  data(){
    return {
      bannerList:[],
      caseList:[],
      service: []
    }
  },
  mounted(){
    // this.initBanner()
    // this.initCase()
    // this.initService()
  },
  methods: {
    //服务覆盖
    initService(){
      service.Query({type:12}).then(resp=>{
        if(resp.data.code == 200){
          console.log("服务覆盖：",resp.data.data.data)
          this.service = resp.data.data.data
        }
      })
    },
    initBanner(){
      banner.Query({}).then(resp=>{
        if(resp.data.code == 200){
          console.log(resp.data.data.data,"bannner ")
          this.bannerList = resp.data.data.data
        }
      })   
    },
    initCase(){
      Case.Query({pageIndex: 1,pageSize: 8,type:null}).then(resp =>{
        if(resp.data.code == 200){
          console.log(resp.data.data.data,"case ")
          this.caseList = resp.data.data.data
        }
      })
    },
    readMoreApply(){
      this.$router.push({
        name:"moreCase"
      })
    }
  }
}
</script>
<style lang="less" scoped>
.case {
  // width: 1200px;
  width: 60%;
  // min-width: 800px;
  // min-width: 1200px;
  margin: 0 auto;
  &_one {
    padding: 30px 0;
    .case_content {
      display: flex;
      justify-content: space-between;
      &_item {
        display: flex;
        align-items: center;
        padding: 3% 0;
        margin-bottom: 10px;
        border: 1px solid #eee;
        border-radius: 10px;
        box-shadow: 0px 0px 20px rgba(40, 123, 255, 0.2);
        width: 22%;
        margin-right: 4%;
        &:last-child {
          margin-right: 0;
        }
        &_l {
          width: 50%;
          img {
            // width: 76px;
            // height: 76px;
            width: 50%;
            height: auto;
          }
        }
        &_r {
          flex: 1;
          // padding-left: 10%;
          .value {
            color: #287BFF;
            font-size: 2rem;
          }
          .name {
            color: #333333;
            font-size: 1rem;
            white-space: nowrap;
          }
        }
      }
    }
    .case_content2 {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      &_item2 {
        position: relative;
        width: 22%;
        margin-right: 4%;
        margin-bottom: 4% ;
        img {
            border-radius: 10px;
            // height: auto;
            height: 100%;
            width: 100%;
        }
        .name {
          color: white;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:nth-child(4n+4) {
          margin-right: 0;
        }
      }
    }
  }
}
.case_conTop {
  text-align: center;
  margin: 40px 0;
  .title {
    font-size: 34px;
    color: #333333;
    font-weight: bold;
  }
  .subtitle {
    color: #999999;
    font-size: 18px;
  }
  .line {
    width: 30px;
    height: 20px;
    margin: 0 auto;
    border-bottom: 6px solid #287bffff;
  }
}

</style>
